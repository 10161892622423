<template>
   <div class="obi-app">
      <slot />
   </div>
</template>

<script>
export default {
   name: 'ObiApp',
   props: {},
};
</script>
<style lang="scss" scoped>
.obi-app {
   width: 100%;
   height: auto;
   min-height: 100vh;
   overflow: visible;
   position: relative;
}
</style>
